import { StorageHelpers } from "carynhealth-component-library";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../components/Header/handlers";
import { getRoutePaths } from "../config/constants/routePaths";

const defaultValue = {
  email: "",
  email_verified: "",
  family_name: "",
  given_name: "",
  name: "",
  phone_number: "",
  phone_number_verified: "",
  sub: "",
  isAuthenticated: false,
  throughEmail: false,
};

export interface AuthInfoType {
  email: string;
  email_verified: string;
  family_name: string;
  given_name: string;
  name: string;
  phone_number: string;
  phone_number_verified: string;
  sub: string;
  isAuthenticated: boolean;
  throughEmail: boolean;
}

interface AuthContextType {
  authenticatedInfo: AuthInfoType;
  updateAuthenticateInfo: (authenticatedInfo: AuthInfoType) => void;
  resetAuthenticateInfo: () => void;
}

// Creating the context object and passing the default values.
export const AuthContext = React.createContext<AuthContextType>({
  authenticatedInfo: defaultValue,
  updateAuthenticateInfo: () => {},
  resetAuthenticateInfo: () => {},
});

const AuthContextWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [authenticatedInfo, setIsAuthenticated] = useState(defaultValue);
  const navigate = useNavigate();

  const updateAuthenticateInfo = (IsAuthenticatedVal: AuthInfoType) => {
    console.log("IsAuthenticatedVal::: ", IsAuthenticatedVal);
    // StorageHelpers.setLocalStorage("userMail", IsAuthenticatedVal?.email || "");
    setIsAuthenticated(IsAuthenticatedVal);
  };

  const resetAuthenticateInfo = () => {
    logout();
    setIsAuthenticated(defaultValue);
    navigate(getRoutePaths.login, {
      replace: true,
    });
  };

  return (
    <>
      <AuthContext.Provider
        value={{
          authenticatedInfo,
          updateAuthenticateInfo,
          resetAuthenticateInfo,
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

export default AuthContextWrapper;
